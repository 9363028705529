import React from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import clsx from 'clsx';
import withDefaults from '../withDefaults';

const styles = {
  root: {},
  paper: {},
};

const OHWDrawer = ({
  classes,
  className: classNamesProp,
  children,
  paperClasses,
  ...rest
}: DrawerProps) => {
  const className = clsx(classes.root, classNamesProp);
  const paperClassName = paperClasses
    ? clsx(paperClasses.root, paperClasses.paper)
    : '';
  return (
    <Drawer
      className={className}
      PaperProps={{ className: paperClassName }}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

export default withDefaults(styles, { name: 'OH-Drawer' })(OHWDrawer);
