import React from 'react';

const ArrowDownSmall = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7255 0.137338C10.8056 0.045723 10.8978 0 11.002 0C11.1062 0 11.1985 0.045723 11.2785 0.137338L11.8797 0.8247C11.9599 0.916315 12 1.02176 12 1.14071C12 1.25983 11.9599 1.36528 11.8797 1.45689L6.27657 7.86266C6.19643 7.95428 6.10419 8 6 8C5.89581 8 5.80357 7.95428 5.72343 7.86266L0.12028 1.45706C0.0399952 1.36545 0 1.26 0 1.14088C0 1.02193 0.0399952 0.916484 0.12028 0.824869L0.72124 0.137506C0.801378 0.0458918 0.893617 0.0001688 0.997958 0.0001688C1.102 0.0001688 1.19424 0.0458918 1.27438 0.137506L6 5.53973L10.7255 0.137338Z"
        fill="#373634"
      />
    </svg>
  );
};

export default ArrowDownSmall;
