import React from 'react';
import ToolTip from '@material-ui/core/Tooltip';
import { TooltipProps } from '@material-ui/core/Tooltip';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { styles } from './index.styles';
import withDefaults from '/withDefaults';

const MuiToolTip = (props: TooltipProps) => {
  return (
    <ToolTip arrow {...props}>
      <InfoOutlinedIcon />
    </ToolTip>
  );
};

export default withDefaults(styles, { name: 'OH-MuiToolTip' })(MuiToolTip);
