import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  colorPrimary: {
    color: 'white',
  },
});

export default function Loader() {
  const classes = useStyles();

  return <CircularProgress size={24} className={classes.colorPrimary} />;
}
