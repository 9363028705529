import React, { ReactNode } from 'react';
import Text from '../Text';
import AccordionDetails, {
  AccordionDetailsProps,
} from '@material-ui/core/AccordionDetails';
import { Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import withDefaults from '../withDefaults';

const styles = (theme: Theme) => ({
  root: {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      marginBottom: '0',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
  },
});

interface OHWAccordionDetailsProps extends AccordionDetailsProps {
  text?: string;
  children?: ReactNode;
}

const OHWAccordionDetails = ({
  classes,
  className: classNamesProp,
  children,
  expandIcon,
  text,
  ...rest
}: OHWAccordionDetailsProps) => {
  const className = clsx(classes.root, classNamesProp);
  return (
    <AccordionDetails
      expandIcon={expandIcon ? expandIcon : <ExpandMoreIcon />}
      className={className}
      {...rest}
    >
      {text ? <Text variant="body2">{text}</Text> : children}
    </AccordionDetails>
  );
};

export default withDefaults(styles, { name: 'OH-AccordionDetails' })(
  OHWAccordionDetails,
);
