import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../theme';
const useStyles = makeStyles({
    colorPrimary: {
        color: theme.palette.primary.main,
    },
});

export default function LoaderDark() {
    const classes = useStyles();

    return <CircularProgress size={24} className={classes.colorPrimary} />;
}
