import Calendar from './assets/calendar';
import Close from './assets/close';
import Facebook from './assets/facebook';
import Hamburger from './assets/hamburger';
import Instagram from './assets/instagram';
import LockClosed from './assets/lock-closed';
import LockOpen from './assets/lock-open';
import Minus from './assets/minus';
import MinusThin from './assets/minus-thin';
import OHLogo from './assets/OH-logo';
import Plus from './assets/plus';
import PlusThin from './assets/plus-thin';
import PlusDisabled from './assets/plus-disabled';
import RightArrow from './assets/right-arrow';
import Rotate from './assets/rotate';
import Search from './assets/search';
import ShoppingBag from './assets/shopping-bag';
import SliderLeftArrow from './assets/slider-left-arrow';
import SliderRightArrow from './assets/slider-right-arrow';
import SliderLeftArrowBlack from './assets/slider-left-arrow-black';
import StarOutline from './assets/star-outline';
import StarsThreeOutline from './assets/stars-three-outline';
import Twitter from './assets/twitter';
import YouTube from './assets/youtube';
import ArrowRightBlack from './assets/arrow-right-black';
import ArrowRightWhite from './assets/arrow-right-white';
import ArrowLeftBlack from './assets/arrow-left-black';
import NavOHLogo from './assets/nav-OH-logo';
import ArrowHeroBannerDown from './assets/arrow-hero-banner-down';
import RadioCheckOn from './assets/radio-check-on';
import ArrowDropDownCircleIcon from './assets/select-menu-arrow-down';
import BottleBlack from './assets/BottleBlack';
import BottleBlack4 from './assets/BottleBlack4';
import BottleBlack6 from './assets/BottleBlack6';
import BottleBlack12 from './assets/BottleBlack12';
import BottleBlue from './assets/BottleBlue';
import BottleBlue4 from './assets/BottleBlue4';
import BottleBlue6 from './assets/BottleBlue6';
import BottleBlue12 from './assets/BottleBlue12';
import BottleGray from './assets/BottleGray';
import BottleGray4 from './assets/BottleGray4';
import BottleGray6 from './assets/BottleGray6';
import BottleGray12 from './assets/BottleGray12';
import SortArrows from './assets/sort-arrows';
import CheckMark from './assets/checkMark';
import Bookmark from './assets/bookmark';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmptyProfile from './assets/empty-profile';
import CheersLarge from './assets/cheers-lg';
import CheersSmall from './assets/cheers-sm';
import CardPlayIcon from './assets/card-play-icon';
import ThreeStars from './assets/three-stars';
import Cash from './assets/cash';
import WineCheers from './assets/wine-cheers';
import SaveDonate from './assets/save-donate';
import CardDropdown from './assets/card-dropdown';
import ArrowUpSmall from './assets/arrow-up-small';
import ArrowDownSmall from './assets/arrow-down-small';
import RadioButtonUnchecked from './assets/radio-button-unchecked';
import CheckCircle from './assets/check-circle';
import WineClubExclusive from './assets/wine-club-exclusive';
import WineClubEarlyRelease from './assets/wine-club-early-release';
import DefaultProfile from './assets/default-profile';
import MudEditPencil from './assets/mud-edit-pencil';
import BlankAvatar from './assets/blank-avatar';
import PhotoUpload from './assets/photo-upload';
import MuiCancel from './assets/cancel';
import FacebookLogin from './assets/facebook-login';
import GoogleLogin from './assets/google-login';
import ErrorAlert from './assets/error-alert';
import InfoAlert from './assets/info-alert';
import ValidationCheckmark from './assets/validation-checkmark';

export default {
  Calendar,
  Cash,
  Close,
  CheckCircle,
  Facebook,
  Hamburger,
  Instagram,
  Minus,
  MinusThin,
  NavOHLogo,
  OHLogo,
  Plus,
  PlusThin,
  PlusDisabled,
  RightArrow,
  Rotate,
  SaveDonate,
  Search,
  ShoppingBag,
  SliderLeftArrow,
  SliderRightArrow,
  SliderLeftArrowBlack,
  Twitter,
  YouTube,
  ArrowRightWhite,
  ArrowRightBlack,
  ArrowHeroBannerDown,
  ArrowLeftBlack,
  RadioCheckOn,
  LocationOnIcon,
  AddCircleIcon,
  ArrowDropDownCircleIcon,
  BottleBlack,
  BottleBlack4,
  BottleBlack6,
  BottleBlack12,
  BottleBlue,
  BottleBlue4,
  BottleBlue6,
  BottleBlue12,
  BottleGray,
  BottleGray4,
  BottleGray6,
  BottleGray12,
  SortArrows,
  CheckMark,
  Bookmark,
  VisibilityIcon,
  VisibilityOffIcon,
  WineCheers,
  ThreeStars,
  EmptyProfile,
  CheersLarge,
  CheersSmall,
  CardPlayIcon,
  LockClosed,
  LockOpen,
  RadioButtonUnchecked,
  StarOutline,
  StarsThreeOutline,
  CardDropdown,
  ArrowUpSmall,
  ArrowDownSmall,
  WineClubExclusive,
  WineClubEarlyRelease,
  DefaultProfile,
  MudEditPencil,
  BlankAvatar,
  PhotoUpload,
  MuiCancel,
  FacebookLogin,
  GoogleLogin,
  ErrorAlert,
  InfoAlert,
  ValidationCheckmark,
};
