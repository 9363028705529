import React, { ReactNode } from 'react';
import Text from '../Text';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import withDefaults from '../withDefaults';

const styles = {
  root: {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: '0.01em',
    },
  },
  content: {},
  expanded: {},
  expandIcon: {},
  subLabel: {},
};

interface OHWAccordionSummaryProps extends AccordionSummaryProps {
  label?: string;
  subLabel?: string;
  children?: ReactNode;
}

const OHWAccordionSummary = ({
  classes,
  className: classNamesProp,
  iconClassName,
  children,
  expandIcon,
  label,
  subLabel,
  ...rest
}: OHWAccordionSummaryProps) => {
  const className = clsx(
    classes.root,
    classes.content,
    classes.expanded,
    classes.expandIcon,
    classNamesProp,
  );
  return (
    <AccordionSummary
      expandIcon={
        expandIcon ? expandIcon : <ExpandMoreIcon className={iconClassName} />
      }
      className={className}
      {...rest}
    >
      {label ? (
        <div>
          <Text variant="body1" component="p">
            {label}
          </Text>
          {subLabel && (
            <Text variant="body1" component="p" className={classes.subLabel}>
              {subLabel}
            </Text>
          )}
        </div>
      ) : (
        children
      )}
    </AccordionSummary>
  );
};

export default withDefaults(styles, { name: 'OH-AccordionSummary' })(
  OHWAccordionSummary,
);
