import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '../withStyles';
import ThemeType from '../theme/ThemeType';

const BlackCheckbox = withStyles((theme: ThemeType) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props: any) => <Checkbox color="default" {...props} />);

export default BlackCheckbox;
