import React from 'react';

const PlusDisabled = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 0V10M0 5H10"
        stroke="#88898A"
        stroke-width="0.75"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PlusDisabled;
