import React from 'react';
import makeStyles from '/makeStyles';
import withDefaults from '/withDefaults';
import clsx from 'clsx';

interface ContentfulImageProps {
  src: string;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  className?: string;
  altText?: string;
  paddingBottom?: string;
}

const styles = {
  root: {},
  fullWidth: {
    width: '100%',
  },
};

const useStyles = makeStyles({
  lazyRoot: (props: { paddingBottom: number }) => ({
    position: 'relative',
    height: 0,
    paddingBottom: `${props.paddingBottom}%`,
  }),
  root: {},
  fullWidth: {
    width: '100%',
  },
  lazyImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const OHWImage = ({
  src,
  width,
  height,
  fullWidth = false,
  className: classNamesProp,
  altText,
}: ContentfulImageProps) => {
  const isLazyImage = !!(height && width);
  const paddingBottom = ((height || 0) / (width || 0)) * 100;
  const classes = useStyles({ paddingBottom });
  const calculatedSource = width ? `${src}?w=${width}` : src;
  const className = clsx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    classNamesProp,
  );

  if (!src) return <div />;

  return (
    <>
      {isLazyImage ? (
        <div className={classes.lazyRoot}>
          <img
            alt={altText}
            className={classes.lazyImage}
            src={calculatedSource}
          />
        </div>
      ) : (
        <img alt={altText} className={className} src={calculatedSource} />
      )}
    </>
  );
};

export default withDefaults(styles, { name: 'OH-Image' })(OHWImage);
