import React from 'react';

const CheckMark = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.76691 9.46756L1.21092 5.73602L0 6.99776L4.76691 12L15 1.26174L13.7976 0L4.76691 9.46756Z"
        fill="#373634"
      />
    </svg>
  );
};

export default CheckMark;
