import React from 'react';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import withDefaults from '../withDefaults';

const styles = (theme: Theme) => ({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '&::before': {
      display: 'none',
    },
    '&:last-child': {
      borderRadius: 0,
    },
  },
});

const OHWAccordion = ({
  classes,
  className: classNamesProp,
  children,
  ...rest
}: AccordionProps) => {
  const className = clsx(classes.root, classNamesProp);
  return (
    <Accordion className={className} {...rest}>
      {children}
    </Accordion>
  );
};

export default withDefaults(styles, { name: 'OH-Accordion' })(OHWAccordion);
