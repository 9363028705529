import React from 'react';

const LockClosed = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24323 2.34238C5.7826 1.80301 6.51413 1.5 7.27691 1.5C8.03969 1.5 8.77123 1.80301 9.3106 2.34238C9.84996 2.88175 10.153 3.61328 10.153 4.37606V6.52691H4.40085V4.37606C4.40085 3.61328 4.70386 2.88175 5.24323 2.34238ZM2.90085 6.52691V4.37606C2.90085 3.21546 3.3619 2.10239 4.18257 1.28172C5.00324 0.461048 6.11631 0 7.27691 0C8.43752 0 9.55058 0.461048 10.3713 1.28172C11.1919 2.10239 11.653 3.21546 11.653 4.37606V6.52691H12.3534C13.5687 6.52691 14.5538 7.51208 14.5538 8.72734V13.8038C14.5538 15.0191 13.5687 16.0042 12.3534 16.0042H2.20042C0.985164 16.0042 0 15.0191 0 13.8038V8.72734C0 7.51208 0.985164 6.52691 2.20042 6.52691H2.90085ZM10.903 8.02691H3.65085L2.20042 8.02691C1.81359 8.02691 1.5 8.3405 1.5 8.72734V13.8038C1.5 14.1907 1.81359 14.5042 2.20042 14.5042H12.3534C12.7402 14.5042 13.0538 14.1907 13.0538 13.8038V8.72734C13.0538 8.3405 12.7402 8.02691 12.3534 8.02691L10.903 8.02691Z"
        fill="#C2C6C9"
      />
    </svg>
  );
};

export default LockClosed;
