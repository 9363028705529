import React from 'react';

const GoogleLogin = (props: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.0984 5.61386V8.38614C7.0984 8.38614 9.8256 8.38253 10.936 8.38253C10.3347 10.1797 9.39969 11.1584 7.0984 11.1584C4.76949 11.1584 2.95181 9.29662 2.95181 7C2.95181 4.70338 4.76949 2.84158 7.0984 2.84158C8.32973 2.84158 9.12496 3.26838 9.85441 3.86324C10.4383 3.28744 10.3895 3.20538 11.8751 1.82194C10.614 0.69002 8.93794 0 7.0984 0C3.17805 0 0 3.13399 0 7C0 10.8659 3.17805 14 7.0984 14C12.9582 14 14.3905 8.96832 13.9157 5.61386H7.0984Z"
        fill="white"
      />
    </svg>
  );
};

export default GoogleLogin;
