// import * as React from 'react';
// import { css } from '@emotion/core';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  font,
  neutral2,
  inputFocusColor,
  errorColor,
  primaryColor,
  typography,
  neutral0,
  errorBackgroundColor,
} from '../constants/CSS';

type InputProps = {
  error?: boolean;
  hasSuccess?: boolean;
  focusError?: boolean;
  startAdornment?: string;
  hasError?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const Input = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${typography.bodyFontFamily};
    height: 42.22px;
    padding: ${({ startAdornment }: InputProps) =>
      startAdornment ? '0.15rem 16px 0.15em 32px' : '0.15rem 16px'};
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    background-color: ${({ hasSuccess, focusError, hasError }: InputProps) =>
      hasSuccess
        ? neutral0
        : hasError
        ? errorBackgroundColor
        : focusError
        ? '#fff5f5'
        : neutral2};
    border-bottom: 1px solid ${primaryColor};
  }
  & .MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : inputFocusColor};

    /* the below is needed to overcome the zoom effect triggered 
       in Safari mobile when an input is focused */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      font-size: 16px;
    }
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }
  & .MuiFormHelperText-root {
    font-family: ${typography.bodyFontFamily};
    color: ${primaryColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    margin-top: 2px;
  }
  & .MuiInputBase-multiline {
    padding: 6px 0px 0px 0px;
  }
  & .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 16px;
  }
  & .MuiInputAdornment-positionStart {
    position: absolute;
    left: 16px;
  }
`;
// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const InputSelect = styled(Select)<InputProps>`
  &.MuiInputBase-input {
    font-family: ${font};
    padding: 1rem 16px;
    min-width: 144px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border: 1px solid red;
    background-color: ${neutral2};
  }
  &.MuiSvgIcon-root {
    fill: ${primaryColor};
  }
  &.MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : inputFocusColor};
  }
  &.MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${typography.bodyFontFamily};
    height: 48px;
    padding: 0.15rem 16px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border-radius: 100px;
    background-color: ${neutral2};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${primaryColor} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }

  & .MuiFormHelperText-root {
    font-family: ${typography.bodyFontFamily};
    color: ${errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;

// export const InputMask =
//   styled(ReactInputMask) <
//   InputProps >
//   `
//   ${baseInputStyle};
//   box-shadow: inset 0 -1px 0 0 ${({ error} ) =>
//     errors ? errorColor : primaryColor};
//   &:hover {
//     box-shadow: inset 0 -1px 0 0 ${({ error} ) =>
//       errors ? errorColor : primaryColor};
//   }
//   &:focus {
//     background-color: ${({ error} ) =>
//       errors ? '#fef8f5' : inputFocusColor};
//     box-shadow: inset 0 -2px 0 0 ${({ error} ) =>
//       errors ? errorColor : primaryColor};
//   }
// `;

export const Label = styled.label`
  font-family: ${typography.bodyFontFamily};
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: 0.01em;
  color: #4f5153;
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const StartAdornment = styled(InputAdornment)`
  & .MuiTypography-colorTextSecondary {
    color: ${primaryColor};
    font-family: ${typography.bodyFontFamily};
  }
`;
