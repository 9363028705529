import Switch from '@material-ui/core/Switch';
import withStyles from '../withStyles';
import theme from '../theme';

const ToggleSwitch = withStyles({
    root: {
        width: 42,
        height: 22,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(20px)',
            color: 'white',
            '& + $track': {
                backgroundColor: theme.palette.blue.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            backgroundColor: theme.palette.blue.main,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 20,
        height: 20,
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.greyMedium.main,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
})(Switch);

export default ToggleSwitch
