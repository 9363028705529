import React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import withDefaults from '../withDefaults';

const styles = (theme: Theme) => ({
  root: {
    padding: '16px 24px',
    height: '48px',
    borderRadius: 0,
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1em',
    fontFamily: theme.bodyFontFamily,
    textTransform: 'uppercase',
  },
  primary: {
    color: 'white',
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
  },
  secondary: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'white' : theme.palette.greyLight.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.greyLight.main}`,
    },
  },
  mud: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
    },
  },
  blue: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.blue.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : `${theme.palette.darkBlue100.main}`,
    },
  },
  red: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyMedium.main
        : theme.palette.secondaryRed.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : `${theme.palette.deepRedText.main}`,
    },
  },
  bronzeNeutral3: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyMedium.main
        : theme.palette.bronzeNeutral3.main,
    '&:hover': {
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.bronzeNeutral3.main}`,
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : theme.palette.bronzeNeutral3.main,
    },
  },
  underlined: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    padding: '0px',
    height: '21px',
    paddingBottom: '6px',
    backgroundColor: 'transparent',
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.mud.main}`,
    fontSize: '14px',
    lineHeight: '21px',
    minWidth: '20px',
    '&:hover': {
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
      backgroundColor: 'transparent',
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.mud.light}`,
    },
  },
  underlineMud: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    height: '21px',
    fontSize: '14px',
    lineHeight: '21px',
    border: 'none',
    padding: '0px',
    paddingBottom: '6px',
    minWidth: '20px',
    backgroundColor: 'transparent',
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.mud.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.mud.light}`,
    },
  },
  hero: {
    height: `56px`,
    fontSize: '16px',
    fontWeight: '400',
  },
});

const OHWButton = ({
  color = 'primary',
  classes,
  type = 'primary',
  variation = 'contained',
  disabled = false,
  className: classNamesProp,
  ...rest
}: ButtonProps) => {
  const className = clsx(
    classes.root,
    {
      [classes.primary]: type === 'primary',
      [classes.secondary]: type === 'secondary',
      [classes.hero]: variation === 'hero',
      [classes.mud]: color === 'mud',
      [classes.blue]: color === 'blue',
      [classes.red]: color === 'red',
      [classes.bronzeNeutral3]: color === 'bronzeNeutral3',
      [classes.underlined]: type === 'underlined',
      [classes.underlineMud]: type === 'underlineMud',
    },
    classNamesProp,
  );
  return (
    <Button
      className={className}
      disabled={disabled}
      variation={variation}
      disableRipple
      {...rest}
    />
  );
};

export default withDefaults(styles, { name: 'OH-Button' })(OHWButton);
