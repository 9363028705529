import styled from '@emotion/styled';
import theme from '../theme';
import TextField from '/TextField';

type InputProps = {
  error?: boolean;
  hasSuccess?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${theme.bodyFontFamily};
    height: 48px;
    padding: 0 16px;
    font-weight: normal;
    line-height: 21px;
    font-size: 14px;
    border-radius: 100px;
    letter-spacing: 0.01em;
    background-color: white;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.primary.main} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : ' #f9fcff'};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? '#DB4437' : theme.palette.primary.main};
  }

  & .MuiFormHelperText-root {
    font-family: ${theme.bodyFontFamily};
    color: #db4437;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiInputAdornment-positionStart {
    left: 8px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }
`;

export const Label = styled.label`
  font-family: ${theme.bodyFontFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4f5153;
`;
