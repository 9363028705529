import React from 'react';

const FacebookLogin = (props: any) => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31979 16H1.77254V7.99899H0V5.24211H1.77254V3.58697C1.77254 1.33805 2.77288 0 5.61674 0H7.98374V2.75789H6.50437C5.39728 2.75789 5.32412 3.14315 5.32412 3.86216L5.31924 5.24211H8L7.68624 7.99899H5.31924V16H5.31979Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookLogin;
