import React from 'react';

const LockOpen = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.4444 8H2.55556C1.69645 8 1 8.73262 1 9.63636V15.3636C1 16.2674 1.69645 17 2.55556 17H13.4444C14.3036 17 15 16.2674 15 15.3636V9.63636C15 8.73262 14.3036 8 13.4444 8Z"
        stroke="#373634"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8V4.89063C3.999 3.92676 4.37033 2.99692 5.04192 2.28161C5.71351 1.5663 6.63744 1.11655 7.63435 1.01969C8.63126 0.922819 9.63002 1.18574 10.4367 1.7574C11.2435 2.32907 11.8006 3.1687 12 4.11329"
        stroke="#373634"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockOpen;
