import React, { ReactNode } from 'react';
import makeStyles from '../makeStyles';
import { white0 } from '../constants/CSS';
import theme from '../theme';

const useStyles = makeStyles({
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      // Change from `box-sizing: content-box` so that `width`
      // is not affected by `padding` or `border`.
      boxSizing: 'border-box',
      overflowX: 'hidden',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    'strong, b': {
      fontWeight: 'bolder',
    },
    body: {
      margin: 0, // Remove the margin in all browsers.
      color: theme.palette.primary.main,
      fontFamily: theme.bodyFontFamily,
      overflowX: 'hidden',
      /* fontSize: '16px', */
      backgroundColor: white0,
      '@media print': {
        // Save printer ink.
        backgroundColor: white0,
      },
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  },
});

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */
const CssBaseline = ({ children = null }: { children?: ReactNode }) => {
  useStyles();
  return <React.Fragment>{children}</React.Fragment>;
};

export default CssBaseline;
