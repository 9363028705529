import * as React from 'react';
import styled from '@emotion/styled';
import BaseInput from '@material-ui/core/Input';
import { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorCircle from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
// import { Theme } from '@material-ui/core/styles';
import { successColor, errorColor } from '../constants/CSS';
import { Input, Label, StartAdornment } from './input.styles';
import MuiToolTip from '../ToolTip';
import withDefaults from '../withDefaults';

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const CheckCircleIcon = styled(CheckCircle)`
  && {
    fill: ${successColor};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const ErrorIcon = styled(ErrorCircle)`
  && {
    fill: ${errorColor};
  }
`;

const styles = () => ({
  root: {
    // fontFamily: theme.bodyFontFamily,
    // height: '42.22px',
    // padding: `${({ startAdornment }: InputProps) =>
    //   startAdornment ? '0.15rem 16px 0.15em 32px' : '0.15rem 16px'}`,
  },
});

type InputContainerProps = TextFieldProps & {
  validate?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  inputComponent?: any;
  mask?: any;
  toolTipText?: string;
  startAdornment?: string | null;
  helperText?: string;
  hasCustomClasses?: boolean;
};

const OHInput = (props: InputContainerProps) => {
  const {
    validate,
    hasError,
    hasSuccess,
    label,
    mask,
    toolTipText,
    hasCustomClasses,
    ...rest
  } = props;
  const maskInput = mask
    ? { inputComponent: mask, value: props.value, onChange: props.onChange }
    : {};

  const getAdornment = () => {
    if (toolTipText)
      return {
        endAdornment: (
          <InputAdornment position="end">
            <MuiToolTip
              title={toolTipText}
              placement="top"
              enterDelay={100}
              leaveDelay={200}
            />
          </InputAdornment>
        ),
        startAdornment: props?.startAdornment && (
          <StartAdornment position="start">
            {props?.startAdornment}
          </StartAdornment>
        ),
        ...maskInput,
      };
    if (validate)
      return {
        endAdornment: (
          <InputAdornment position="end">
            {hasError ? (
              <ErrorIcon />
            ) : (
              props.value && hasSuccess ? <CheckCircleIcon /> : <div/>
            )}
          </InputAdornment>
        ),
        startAdornment: props?.startAdornment && (
          <StartAdornment position="start">
            {props?.startAdornment}
          </StartAdornment>
        ),
        ...maskInput,
      };
    return {
      startAdornment: props?.startAdornment && (
        <StartAdornment position="start">
          {props?.startAdornment}
        </StartAdornment>
      ),
      ...maskInput,
    };
  };

  if (hasCustomClasses) {
    return <BaseInput {...rest} />;
  }
  return (
    <React.Fragment>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <Input InputProps={getAdornment()} {...rest} hasError={hasError} />
    </React.Fragment>
  );
};

export default withDefaults(styles, { name: 'OH-Input' })(OHInput);
