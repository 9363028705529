import { createTheme } from '@material-ui/core/styles';
import {
  secondaryTextColor,
  primaryColors,
  neutralColors,
  greyColors,
  neutral0,
  typography,
  rose0,
  bronzeNeutral3,
  blossom,
  bronzeNeutral0,
  deepRedText,
  linkColor,
  redText,
  secondaryRed,
  teal,
  tealTint,
  neutral2,
} from './../constants/CSS';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    headerFontFamily: string;
    bodyFontFamily: string;
    accentFontFamily: string;
  }
  interface ThemeOptions {
    headerFontFamily: string;
    bodyFontFamily: string;
    accentFontFamily: string;
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    activeGrey: Palette['primary'];
    blue: Palette['primary'];
    darkBlue100: Palette['primary'];
    darkBlue80: Palette['primary'];
    mud: Palette['primary'];
    lightOrangeTint: Palette['primary'];
    lightBlueTint: Palette['primary'];
    orangeCream: Palette['primary'];
    neutral0: Palette['primary'];
    greyDark: Palette['primary'];
    greyMedium: Palette['primary'];
    greyLight: Palette['primary'];
    veryLightGrey: Palette['primary'];
    barelyThereGrey: Palette['primary'];
    rose0: Palette['primary'];
    bronzeNeutral3: Palette['primary'];
    blossom: Palette['primary'];
    darkYellowBeige: Palette['primary'];
    lightYellowBeige: Palette['primary'];
    gold: Palette['primary'];
    bronzeNeutral0: Palette['primary'];
    deepRedText: Palette['primary'];
    linkColor: Palette['primary'];
    redText: Palette['primary'];
    secondaryRed: Palette['primary'];
    teal: Palette['primary'];
    tealTint: Palette['primary'];
    neutral2: Palette['primary'];
  }
  interface PaletteOptions {
    activeGrey: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    darkBlue100: PaletteOptions['primary'];
    darkBlue80: PaletteOptions['primary'];
    mud: PaletteOptions['primary'];
    lightOrangeTint: PaletteOptions['primary'];
    lightBlueTint: PaletteOptions['primary'];
    orangeCream: PaletteOptions['primary'];
    neutral0: PaletteOptions['primary'];
    greyDark: PaletteOptions['primary'];
    greyMedium: PaletteOptions['primary'];
    greyLight: PaletteOptions['primary'];
    veryLightGrey: PaletteOptions['primary'];
    barelyThereGrey: PaletteOptions['primary'];
    rose0: PaletteOptions['primary'];
    bronzeNeutral3: PaletteOptions['primary'];
    blossom: PaletteOptions['primary'];
    darkYellowBeige: PaletteOptions['primary'];
    lightYellowBeige: PaletteOptions['primary'];
    gold: PaletteOptions['primary'];
    bronzeNeutral0: PaletteOptions['primary'];
    deepRedText: PaletteOptions['primary'];
    linkColor: PaletteOptions['primary'];
    redText: PaletteOptions['primary'];
    secondaryRed: PaletteOptions['primary'];
    teal: PaletteOptions['primary'];
    tealTint: PaletteOptions['primary'];
    neutral2: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColors.black,
      light: primaryColors.blackHover,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: secondaryTextColor,
    },
    activeGrey: {
      main: greyColors.activeGrey,
    },
    darkBlue100: {
      main: primaryColors.darkBlue100,
    },
    darkBlue80: {
      main: primaryColors.darkBlue80,
    },
    blue: {
      main: primaryColors.blue,
    },
    mud: {
      main: primaryColors.mud,
      light: primaryColors.mudHover,
    },
    lightBlueTint: {
      main: neutralColors.lightBlueTint,
    },
    lightOrangeTint: {
      main: neutralColors.lightOrangeTint,
    },
    orangeCream: {
      main: neutralColors.orangeCream,
    },
    neutral0: {
      main: neutral0,
    },
    greyDark: {
      main: greyColors.greyDark,
    },
    greyMedium: {
      main: greyColors.greyMedium,
    },
    greyLight: {
      main: greyColors.lightGrey,
    },
    veryLightGrey: {
      main: greyColors.veryLightGrey,
    },
    barelyThereGrey: {
      main: greyColors.barelyThereGrey,
    },
    rose0: {
      main: rose0,
    },
    bronzeNeutral3: {
      main: bronzeNeutral3,
    },
    blossom: {
      main: blossom,
    },
    darkYellowBeige: {
      main: neutralColors.darkYellowBeige,
    },
    lightYellowBeige: {
      main: neutralColors.lightYellowBeige,
    },
    gold: {
      main: neutralColors.gold,
    },
    bronzeNeutral0: {
      main: bronzeNeutral0,
    },
    deepRedText: {
      main: deepRedText,
    },
    linkColor: {
      main: linkColor,
    },
    redText: {
      main: redText,
    },
    secondaryRed: {
      main: secondaryRed,
    },
    teal: {
      main: teal,
    },
    tealTint: {
      main: tealTint,
    },
    neutral2: {
      main: neutral2,
    },
  },
  // Typography fonts
  headerFontFamily: typography.headerFontFamily,
  bodyFontFamily: typography.bodyFontFamily,
  accentFontFamily: typography.accentFontFamily,
  // MUI Typography Overrides
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        fontFamily: `${typography.bodyFontFamily}`,
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

export default theme;
