import * as React from 'react';
import LuxonUtils from '@date-io/luxon';
import {
  MuiPickersUtilsProvider,
  DatePickerProps,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Calendar from '../Icons/assets/calendar';
import { Label } from '../Input/input.styles';
import { makeStyles } from '@material-ui/core/styles';

const useLeftButtonStyles = makeStyles({
  root: {
    position: 'absolute',
    right: '50px',
    top: 0,
  },
});

const useRightButtonStyles = makeStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const useButtonStyles = makeStyles({
  root: {
    opacity: 1,
  },
});

type DatePickerContainerProps = DatePickerProps & {
  label?: string;
};

export default function DatePickerContainer(props: DatePickerContainerProps) {
  const { label, ...rest } = props;
  const buttonClasses = useButtonStyles();
  const leftButtonClasses = useLeftButtonStyles();
  const rightButtonClasses = useRightButtonStyles();
  return (
    <>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}

      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <KeyboardDatePicker
          disableToolbar
          disablePast
          format="MM/dd/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: buttonClasses,
          }}
          keyboardIcon={<Calendar />}
          variant="inline"
          leftArrowButtonProps={{
            classes: leftButtonClasses,
          }}
          rightArrowButtonProps={{
            classes: rightButtonClasses,
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}
