import React from 'react';
import clsx from 'clsx';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { StylesProvider, Theme } from '@material-ui/core';
import withDefaults from '../withDefaults';

// constants for max and min calculated
// font-size based on viewport
// const maximumViewport = 1440;
// const minimumViewport = 320;

// calculate fluid type font-size
// const calcFontSize = (maxPixel: number, minPixel: number) => ({
//   fontSize: `${minPixel}px`,
//   [`@media (min-width: ${minimumViewport}px)`]: {
//     fontSize: `calc(${minPixel}px + (${maxPixel} - ${minPixel}) * ((100vw - ${minimumViewport}px) / (${maximumViewport} - ${minimumViewport})))`,
//   },
//   [`@media (min-width: ${maximumViewport}px)`]: {
//     fontSize: `${maxPixel}px`,
//   },
// });

const styles = (theme: Theme) => ({
  root: {
    fontFamily: theme.bodyFontFamily,
  },
  h1: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: '0.03em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '44px',
      lineHeight: '60px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.025em',
      fontSize: '60px',
      lineHeight: '74px',
    },
  },
  h2: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0.04em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '44px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.03em',
      fontSize: '44px',
      lineHeight: '60px',
    },
  },
  h3: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.04em',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.03em',
      fontSize: '32px',
      lineHeight: '44px',
    },
  },
  h4: {
    fontWeight: 300,
    margin: '0px',
    fontFamily: theme.headerFontFamily,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.08em',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.1em',
      fontSize: '28px',
      lineHeight: '42px',
    },
  },
  h5: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    margin: '0px',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '27x',
    },
  },
  h6: {
    fontFamily: theme.accentFontFamily,
    fontWeight: 500,
    margin: '0px',
    fontSize: '48px',
    lineHeight: '65px',
    letterSpacing: '0.01em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '64px',
      lineHeight: '87px',
    },
  },
  blockquote: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    margin: '0px',
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing: '0.01em',
    fontStyle: 'italic',
  },
  body1: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    margin: '0px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  body2: {},
});

const Text = ({
  children,
  classes,
  className: classNamesProp,
  component,
  variant,
  ...rest
}: TypographyProps) => {
  const className = clsx(
    classes.root,
    {
      [classes.h1]: variant === 'h1',
      [classes.h2]: variant === 'h2',
      [classes.h3]: variant === 'h3',
      [classes.h4]: variant === 'h4',
      [classes.h5]: variant === 'h5',
      [classes.h6]: variant === 'h6',
      [classes.blockquote]: variant === 'caption',
      [classes.body1]: variant === 'body1',
      [classes.body2]: variant === 'body2',
    },
    classNamesProp,
  );
  return (
    <StylesProvider injectFirst>
      <Typography
        className={className}
        variant={variant}
        component={component ? component : variant}
        {...rest}
      >
        {children}
      </Typography>
    </StylesProvider>
  );
};

export default withDefaults(styles, { name: 'OH-Text' })(Text);
